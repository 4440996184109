<script lang="ts">
	import cn from 'classnames';
	import { browser } from '$app/environment';
	let cls =
		'shrink-0 flex h-12 w-12 items-center justify-center text-center cursor-pointer bg-gray-geyser';
	let currentCls = '!bg-gray-shark font-bold text-white';
	export let max: number = 5;
	export let current: number = 1;
	// use <a href=xx> instead of <li> with click event
	export let use_href: boolean = false;
	export let prefix: string =	'';
</script>

{#if max != 0}
<ul
	class={cn('mx-auto flex flex-row flex-wrap justify-center gap-x-2.5 gap-y-2.5 ', $$props.class)}
>
	{#each Array(max) as _, i}
		{#if use_href}
			<a href="{prefix}/{i + 1}/" class={cn(cls, '', i + 1 === current && currentCls)}>
				{i + 1}
			</a>
			{:else}
		<li
			class={cn(cls, '', i + 1 === current && currentCls)}
			on:click={() => {
				current = i + 1;
				if (browser) {
					window.location.href = `?page=${current}`;
				}
			}}
			on:keydown
			on:keyup
		>
			{i + 1}
		</li>
			{/if}
	{/each}
	<!-- <li class={cn(cls, 'w-7 bg-white')}><a href="/">...</a></li> -->
</ul>
{/if}
<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 10 10"
	enable-background="new 0 0 10 10"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<g>
		<path
			fill="currentColor"
			d="M8.908,3.862H6.277c0.037-0.11,0.082-0.266,0.138-0.486C6.641,2.469,6.851,1.45,6.726,1.078
		C6.532,0.504,5.848-0.313,5.011,0.124C4.567,0.355,4.857,1.271,4.819,1.77C4.78,2.278,4.702,2.594,4.545,3.064
		C4.235,3.996,3.144,4.641,3.144,4.641v4.965c0,0,0.665,0.272,0.949,0.312c0.961,0.133,3.009,0.076,4.28,0.033
		c0.493-0.017,0.906-0.376,0.991-0.862l0.572-4.001C10.049,4.448,9.557,3.862,8.908,3.862z"
		/>
		<rect x="0.047" y="5.4" fill="currentColor" width="1.869" height="4.6" />
	</g>
</svg>

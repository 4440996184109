import { browser, dev } from '$app/environment';

export function debug(message: string, name: any = '') {
	if (browser && name === '') name = window.location.pathname;
	if (dev) console.log(`[DEBUG] [${name}] ${message}`);
}

export function log(message: string) {
	console.log(`[LOG] ${message}`);
}

<script lang="ts">
	import LikeIcon from '$lib/images/svg/like.svelte';
	import Tag from './Tag.svelte';

	// action can be one of the following:
	// R - recommended
	// B - payment in advance
	// C - sale
	// A - discount
	// U - used
	// P - new
	export let action: string;
	// article is the object of the article
	export let article: any;
	// Set isProductDetail to true if you are on product detail page
	// - this will change the position of the tag
	export let isProductDetail: boolean = false;
	function epicCalculationOfDiscount() {
		let _price_before_discount =
			( article?.price_before_discount !== undefined 
				&& article?.price_before_discount !== null  ) &&
		article?.price_before_discount > 0 ?  article?.price_before_discount  : 0;
		return _price_before_discount
			? Math.round(100 - (article?.price / (article?.price_before_discount)) * 100)
			: 0;
	}
</script>

{#if action === 'R'}
	{#if isProductDetail}
		<Tag color="green-emerald">Doporučujeme</Tag>
	{:else}
		<Tag color="green-emerald" class="{isProductDetail ? '': 'absolute' } left-0 top-0 z-10 inline-flex flex-row gap-x-2"
			><LikeIcon class="w-3" /><span>Doporučujeme</span></Tag
		>
	{/if}
{/if}

{#if action === 'B'}
	{#if isProductDetail}
		<Tag color="red">Platba předem</Tag>
	{:else}
		<Tag color="gray-hitgray" class="{isProductDetail ? '': 'absolute' } left-0 top-0 z-10">Platba předem</Tag>
	{/if}
{/if}

{#if action === 'C'}
	<Tag color="red-vividtangerine" class="{isProductDetail ? '': 'absolute' } left-0 top-0 z-10">Výprodej</Tag>
{/if}

{#if action === 'A'}
	{#if article?.has_variants !== true}
		<Tag color="yellow" class="{isProductDetail ? '': 'absolute' } left-0 top-0 z-10">
			{#if epicCalculationOfDiscount() > 0 }
				Sleva {epicCalculationOfDiscount()} %
			{:else}
				Sleva
			{/if}
		</Tag>
	{/if}
{/if}

{#if article?.used != null && article.used}
	<Tag color="red-orientalpink" class="{isProductDetail ? '': 'absolute' } left-0 top-0 z-10">Použitý</Tag>
{/if}

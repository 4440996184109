<script lang="ts">
	import cn from 'classnames';
	export let availability_location: string;
	export let availability_time: string | null = null;
	export let availability_on_stock: number | null = null;
	export let isDetail: boolean = false;
</script>

<div
	class={cn(
		$$props.class,
		'whitespace-nowrap first-letter:uppercase',
		availability_location === 'na objednávku'
			? 'text-yellow-gamboge'
			: availability_location === 'nedostupné'
				? 'text-red'
				: 'text-green'
	)}
>
	{#if availability_location}
		<span>{availability_location}
			{#if availability_on_stock && isDetail}
				<span class="text-gray-oslogray">({availability_on_stock <= 10 ? availability_on_stock : '> 10'} ks)</span>
			{/if}
		</span>
	{/if}
	{#if availability_time}<span class="text-gray-oslogray">({availability_time})</span>{/if}
</div>
